<template>
  <div class="error-page">
    <div class="block">
      <div class="title">{{$t('title.page_not_found')}}</div>
      <div class="action-btns">
        <router-link class="btn" :to="{ name: 'event-list' }">
          {{$t('events.create.toEventsList')}}
        </router-link>

      </div>
    </div>
    <canvas id="canvas" v-on:mousedown="handleMouseDown($event)" v-on:mouseup="handleMouseUp($event)"
            v-on:mousemove="handleMouseMove($event)"
            width="800px" height="800px"></canvas>

  </div>
</template>

<script lang="js">
import Vue from 'vue';

const PageNotFound = Vue.extend({
  name: 'page-not-found',
  computed: {
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    currentMouse() {
      const c = document.getElementById('canvas');
      const rect = c.getBoundingClientRect();

      return {
        x: this.mouse.current.x - rect.left,
        y: this.mouse.current.y - rect.top,
      };
    },
  },

  data() {
    return {
      mouse: {
        current: {
          x: 0,
          y: 0,
        },
        previous: {
          x: 0,
          y: 0,
        },
        down: false,
      },
    };
  },
  ready() {
    const c = document.getElementById('canvas');
    const ctx = c.getContext('2d');
    ctx.translate(0.5, 0.5);
    ctx.imageSmoothingEnabled = false;
    this.draw();
  },
  methods: {
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    draw() {
      requestAnimationFrame(this.draw);
      if (this.mouse.down) {
        const c = document.getElementById('canvas');
        const ctx = c.getContext('2d');

        ctx.clearRect(0, 0, 800, 800);

        ctx.lineTo(this.currentMouse.x, this.currentMouse.y);
        ctx.strokeStyle = '#fff';
        ctx.lineWidth = 2;
        ctx.stroke();
      }
    },
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    handleMouseDown(event) {
      this.mouse.down = true;
      this.mouse.current = {
        x: event.pageX,
        y: event.pageY,
      };
      const c = document.getElementById('canvas');
      const ctx = c.getContext('2d');
      ctx.moveTo(this.currentMouse.x, this.currentMouse.y);
    },
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    handleMouseUp() {
      this.mouse.down = false;
    },
    // TODO: refactoring
    // eslint-disable-next-line @typescript-eslint/typedef,@typescript-eslint/explicit-function-return-type
    handleMouseMove(event) {

      this.mouse.current = {
        x: event.pageX,
        y: event.pageY,
      };

      this.draw(event);
    },
  },
});

export default PageNotFound;
</script>
<style scoped lang="scss">

  .error-page {
    background-color: #35cc98;
  }

  .block {
    padding-top: 100px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 40px;
      color: $ew-white;
    }

    .action-btns {
      margin-top: 30px;

      .btn {
        background-color: #fff;
        border-radius: 25px;
        opacity: 1;
        color: #35cc98;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 8px 30px;
      }
    }
  }

  canvas {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 800px;
  }

</style>
